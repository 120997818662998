import React from "react";
import Menu from "./Menu";

import * as s from "./styles.module.scss";

import { default as footerConfig } from "./footerConfig.json";

const date = new Date();

export default function Footer() {
    return (
        <footer className={s.footer}>
            <div className={s.container}>
                <div className="row">
                    <div className={`col-xs-12 col-sm-6`}>
                        <div className={s.bigLink}>
                            <a href="/publications">Publications</a>
                        </div>
                        <div className={s.bigLink}>
                            <a href="/#explore">Explore</a>
                        </div>
                        <div className={s.bigLink}>
                            <a href="/equipment">Equipment</a>
                        </div>
                    </div>
                    {footerConfig?.menu &&
                        footerConfig.menu?.length &&
                        footerConfig.menu.map((item, key) => (
                            <Menu
                                key={key}
                                title={item.title}
                                links={item.links as any}
                            />
                        ))}
                </div>
            </div>
            <div className={s.footerCopyright}>
                <ul className={s.copyright}>
                    <li>
                        {date && date.getFullYear()} &copy; Panagiotis Andreou
                    </li>
                </ul>
            </div>
        </footer>
    );
}
