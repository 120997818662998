// extracted by mini-css-extract-plugin
export var inputGroupSm = "styles-module--input-group-sm--1MnEl";
export var inputGroupLg = "styles-module--input-group-lg--1k5KF";
export var headerMenu = "styles-module--header-menu--3C3JH";
export var megamenu = "styles-module--megamenu--17dS5";
export var isOpen = "styles-module--is-open--VhmWX";
export var menuItem = "styles-module--menu-item--1Du3T";
export var item = "styles-module--item--Ad2Qj";
export var search = "styles-module--search--3y9PI";
export var itemTitle = "styles-module--item-title--3OsIR";
export var itemBtn = "styles-module--item-btn--1I5v7";
export var arrow = "styles-module--arrow--2kWJQ";