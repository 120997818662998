export default {
    baseUrl: "https://astrotakis.com",
    imageCdnUrl:
        process.env.IMAGE_CDN_URL ||
        "https://res.cloudinary.com/orion99/image/upload/f_auto/v1564616427/ASTROTAKIS",
    imageCdnUrlOpt:
        process.env.IMAGE_CDN_URL ||
        "https://res.cloudinary.com/orion99/image/upload/f_auto/v1564616427/ASTROTAKIS",
    contentfulCdnUrl:
        process.env.CONTENTFUL_CDN_URL ||
        "http://images.ctfassets.net/lb78811wph3w",
};
