import React from "react";
import classnames from "classnames";

import { IHeadingProps } from "./types";

import * as s from "./styles.module.scss";

export default function Heading(props: IHeadingProps) {
    const { level, size, display, children, className, ...otherProps } = props;

    const componentProps = {
        className: classnames(s.heading, s[size], className),
        ...otherProps,
    };

    if (display === "inline") {
        return <span {...componentProps}>{children}</span>;
    }

    switch (level) {
        case 1:
            return <h1 {...componentProps}>{children}</h1>;
        case 2:
            return <h2 {...componentProps}>{children}</h2>;
        case 3:
            return <h3 {...componentProps}>{children}</h3>;
        case 4:
            return <h4 {...componentProps}>{children}</h4>;
        case 5:
            return <h5 {...componentProps}>{children}</h5>;
        case 6:
            return <h6 {...componentProps}>{children}</h6>;
    }

    return <p {...componentProps}>{children}</p>;
}
