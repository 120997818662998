import React, { SyntheticEvent, KeyboardEvent, useState } from "react";
import classnames from "classnames";
import Arrow from "../../../../atoms/Arrow";
import keycode from "../../../../../configuration/keycode";
import MegaMenu from "../MegaMenu";

import { IHeaderMenuProps } from "./types";

import { default as headerConfig } from "../../headerConfig.json";

import * as s from "./styles.module.scss";

export default function HeaderMenu(props: IHeaderMenuProps) {
    const { isMobileMenuOpen, currentCat } = props;

    const [showMegaMenu, setShowMegaMenu] = useState(false);

    const touch: boolean =
        typeof window !== "undefined" && "ontouchstart" in window;
    let openTimer: NodeJS.Timer | any;
    let closeTimer: NodeJS.Timer | any;

    const openMegaMenu = (e?: SyntheticEvent) => {
        setShowMegaMenu(true);
        if (e && touch && !showMegaMenu) {
            e.preventDefault();
        }
    };

    const closeMegaMenu = () => {
        setShowMegaMenu(false);
    };

    const onMouseEnter = (e: SyntheticEvent) => {
        if (!touch) {
            closeTimer = clearTimeout(closeTimer);
            openTimer = setTimeout(() => {
                openMegaMenu(e);
            }, 100);
        }
    };

    const onMouseLeave = () => {
        if (!touch) {
            openTimer = clearTimeout(openTimer);
            closeTimer = setTimeout(() => {
                closeMegaMenu();
            }, 100);
        }
    };

    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === keycode.space) {
            e.preventDefault();
            showMegaMenu ? closeMegaMenu() : openMegaMenu();
        }
        if (e.keyCode == keycode.escape) {
            closeMegaMenu();
        }
    };

    if (isMobileMenuOpen) return null;

    return (
        <div
            className={classnames(s.headerMenu, "hidden-xs", {
                [`${s.isOpen}`]: showMegaMenu,
            })}
        >
            <div
                className={s.menuItem}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onKeyDown={onKeyDown}
            >
                <a
                    href="/#explore"
                    className={s.item}
                    aria-label="exploreHeaderLink"
                >
                    <span className={`${s.itemTitle}`} id="exploreHeaderLink">
                        Explore
                    </span>
                </a>
                <Arrow
                    className={s.arrow}
                    direction={showMegaMenu ? "up" : "down"}
                />
                <div onBlur={onMouseLeave} onFocus={onMouseEnter}>
                    <MegaMenu
                        className={s.megamenu}
                        children={headerConfig.main.menu[0].children}
                        currentCat={currentCat}
                    />
                </div>
            </div>

            <div className={s.menuItem}>
                <a
                    href="/publications"
                    className={s.item}
                    aria-label="publicationsHeaderLink"
                >
                    <span
                        className={`${s.itemTitle}`}
                        id="publicationsHeaderLink"
                    >
                        Publications
                    </span>
                </a>
            </div>

            <div className={s.menuItem}>
                <a
                    href="/equipment"
                    className={s.item}
                    aria-label="equipmentLink"
                >
                    <span className={`${s.itemTitle}`} id="equipmentHeaderLink">
                        Equipment
                    </span>
                </a>
            </div>

            <div className={s.menuItem}>
                <a href="/about" className={s.item} aria-label="aboutLink">
                    <span className={`${s.itemTitle}`} id="aboutHeaderLink">
                        About
                    </span>
                </a>
            </div>

            <div className={s.menuItem}>
                <a
                    href="/contact"
                    className={s.item}
                    aria-label="contactHeaderLink"
                >
                    <span className={`${s.itemTitle}`} id="contactHeaderLink">
                        Contact
                    </span>
                </a>
            </div>
        </div>
    );
}
