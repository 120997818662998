import React, { useState, useEffect } from "react";
import classnames from "classnames";

import {
    supportsNativeSmoothScroll,
    smoothScrollTo,
} from "../../../helpers/Browser";

import { IBouncingArrowProps } from "./types";

import * as s from "./styles.module.scss";

export default function BouncingArrow(props: IBouncingArrowProps) {
    const { propRef, title } = props;
    const [visible, setVisible] = useState(
        typeof window !== "undefined" && window.pageYOffset === 0 ? true : false
    );

    useEffect(() => {
        typeof window !== "undefined" &&
            window.addEventListener("scroll", watchScroll);

        return () => {
            typeof window !== "undefined" &&
                window.removeEventListener("scroll", watchScroll);
        };
    });

    function watchScroll() {
        if (typeof window !== "undefined" && window.pageYOffset === 0) {
            if (!visible) {
                setVisible(true);
            }
        } else if (visible) {
            setVisible(false);
        }
    }

    function watchScrollOnce() {
        if (visible) {
            setVisible(false);
            window.removeEventListener("scroll", watchScrollOnce);
        }
    }

    function scrollToRef() {
        if (typeof window === "undefined" || typeof propRef === "undefined") {
            return;
        }

        if (supportsNativeSmoothScroll) {
            window.scrollTo({
                top: propRef.current.offsetTop,
                behavior: "smooth",
            });
        } else {
            smoothScrollTo(propRef.current.offsetTop, 600);
        }
        /*
            if (visible) {
                setVisible(false);
            }
        */
    }

    return (
        <div className={s.wrapper} onClick={scrollToRef}>
            {visible && (
                <div
                    className={classnames(s.content, {
                        [s.fadeIn]: visible,
                        [s.fadeOut]: !visible,
                    })}
                >
                    {title ? (
                        <p className={s.title}>{title}</p>
                    ) : (
                        <div
                            className={classnames(
                                s.bounce,
                                s.first,
                                s.arrowDown
                            )}
                        />
                    )}
                    <div className={classnames(s.bounce, s.arrowDown)} />
                </div>
            )}
        </div>
    );
}
