import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import BouncingArrow from "../../atoms/BouncingArrow";
import Hero from "../../molecules/Hero";
import Facebook from "../../../configuration/Facebook";
import Google from "../../../configuration/Google";
import Footer from "../../organisms/Footer";
import Nav from "../Nav";

import { IPageProps } from "./types";

const s = require("./styles.module.scss");
const { fbAppId } = Facebook;
const { gaTrackingId } = Google;

export default function Page(props: IPageProps) {
    const {
        children,
        heroImages,
        heroTitle = "",
        heroSubTitle = "",
        heroBgPosition,
        metaTitle,
        metaDescription,
        openGraph,
        canonicalUrl,
        currentCat = "",
        isFullBanner = true,
    } = props;

    const scrollingRef = useRef();

    return (
        <>
            <Helmet
                title={
                    metaTitle
                        ? `${metaTitle} - Astrotakis`
                        : "Astropphotography by Panagiotis Andreou - Astrotakis"
                }
                meta={[
                    {
                        name: "description",
                        content: metaDescription
                            ? metaDescription
                            : "Photographs that show the beauty of the cosmos, make us think, wonder and dream.",
                    },
                    {
                        property: "og:url",
                        content:
                            openGraph && openGraph.url
                                ? openGraph.url
                                : typeof window !== "undefined" &&
                                  window.location.href,
                    },
                    {
                        property: "og:type",
                        content:
                            openGraph && openGraph.type
                                ? openGraph.type
                                : "website",
                    },
                    {
                        property: "og:title",
                        content:
                            openGraph && openGraph.title
                                ? openGraph.title
                                : `${metaTitle} - Astrotakis`,
                    },
                    {
                        propert: "og:description",
                        content:
                            openGraph && openGraph.description
                                ? openGraph.description
                                : metaDescription,
                    },
                    {
                        property: "og:image",
                        content:
                            openGraph && openGraph.img
                                ? openGraph.img
                                : heroImages[2] && heroImages[2],
                    },
                    {
                        property: "fb:app_id",
                        content: fbAppId,
                    },
                    {
                        name: "google-site-verification",
                        content: "wfw-SOBVdo8_nWN7up_UDLUPjA7M-j3zPBWKutkwGgI",
                    },
                ]}
                link={[
                    {
                        rel: "canonical",
                        href: canonicalUrl,
                    },
                ]}
                script={[
                    {
                        async: true,
                        src: "https://www.googletagmanager.com/gtag/js?id=G-EM57EN96L7",
                    },
                    {
                        type: "text/javascript",
                        innerHTML: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${gaTrackingId}');`,
                    },
                ]}
            />

            <Nav currentCat={currentCat} />
            <Hero
                className={s.hero}
                title={heroTitle}
                subTitle={heroSubTitle}
                images={heroImages}
                heroBgPosition={heroBgPosition}
                isFullBanner={isFullBanner}
            />
            {isFullBanner && <BouncingArrow propRef={scrollingRef} />}
            <div ref={scrollingRef} />
            {children}
            <Footer />
        </>
    );
}
