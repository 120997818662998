import React from "react";

import { IBurgerProps } from "./types";

import * as s from "./styles.module.scss";

export default function BurgerButton(props: IBurgerProps) {
    const { mobileMenuOpen, toggle } = props;

    return (
        <button
            type="button"
            onClick={toggle}
            className={mobileMenuOpen ? s.navToggleOpen : s.navToggle}
        >
            <span className="sr-only">Toggle navigation</span>
            <span className={s.b1} />
            <span className={s.b2} />
            <span className={s.b3} />
        </button>
    );
}
