import React from "react";

import { ISecondLevelMenuItemProps } from "./types";

import * as s from "./styles.module.scss";

export default function SecondLevelMenuItem(props: ISecondLevelMenuItemProps) {
    const { index, activeSecondLevelIndex, title, links, toggle } = props;

    return (
        <div>
            <div className={s.secondLevelMenuItemTitle} onClick={() => toggle}>
                {title}
            </div>
            {activeSecondLevelIndex === index && (
                <ul className={s.secondLevelDropdown}>
                    {links.map((secondLevelChild, index) => (
                        <li
                            className={s.secondLevelMenuItemChildren}
                            key={`second-level-menu-item-${index}`}
                        >
                            <img src={secondLevelChild.image} />
                            <a href={secondLevelChild.href}>
                                {secondLevelChild.title}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
