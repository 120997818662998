// extracted by mini-css-extract-plugin
export var inputGroupSm = "styles-module--input-group-sm--1pneR";
export var inputGroupLg = "styles-module--input-group-lg--KPzhs";
export var heading = "styles-module--heading--2t4oZ";
export var xxsmall = "styles-module--xxsmall--gZTJn";
export var xsmall = "styles-module--xsmall--oCw2p";
export var small = "styles-module--small--pN_Ve";
export var medium = "styles-module--medium--3_IvR";
export var large = "styles-module--large--hJgeA";
export var xlarge = "styles-module--xlarge--3fZXY";
export var xxlarge = "styles-module--xxlarge--2SLH5";
export var verticalLine = "styles-module--vertical-line--2dHy4";