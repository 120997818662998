import React from "react";
import classnames from "classnames";

import * as s from "./styles.module.scss";

type direction = "up" | "down";

export default function Arrow(props: {
    className: string;
    direction: direction;
}) {
    const { direction } = props;

    return (
        <span
            className={classnames(props.className, s.arrow, {
                [s.up]: direction === "up",
                [s.down]: direction === "down",
            })}
        />
    );
}
