import React from "react";
import { IMenuProps, IMenuLink } from "./types";

import * as s from "./styles.module.scss";

export default function Menu(props: IMenuProps) {
    const { title, links } = props;

    const renderLinks = (links: IMenuLink[]) => {
        return (
            links &&
            links.map(({ title, href, isLink, isExternal }, key) => (
                <li key={key}>
                    {isLink ? (
                        isExternal ? (
                            <a href={href} target="_blank">
                                {title}
                            </a>
                        ) : (
                            <a href={href}>{title}</a>
                        )
                    ) : (
                        title
                    )}
                </li>
            ))
        );
    };

    return (
        <div className={`col-xs-6 col-sm-3 ${s.menu}`}>
            <ul data-test-id={`footer-${title}`}>
                <li>{title}</li>
                {renderLinks(links)}
            </ul>
        </div>
    );
}
