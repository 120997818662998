// extracted by mini-css-extract-plugin
export var inputGroupSm = "styles-module--input-group-sm--uHGk_";
export var inputGroupLg = "styles-module--input-group-lg--16YHx";
export var containerWrapper = "styles-module--container-wrapper--33Zpc";
export var container = "styles-module--container--3R0ec";
export var hero = "styles-module--hero--2IhxZ";
export var heading = "styles-module--heading--Bmzdz";
export var mediumHeading = "styles-module--medium-heading--27bqe";
export var target = "styles-module--target--3UA0Q";
export var deepSky = "styles-module--deepSky--31neE";
export var starScape = "styles-module--starScape--lZeUv";
export var solarSystem = "styles-module--solarSystem--3vH84";
export var events = "styles-module--events--1jLyq";