import React from "react";
import { Link } from "gatsby";

import * as s from "./styles.module.scss";

export default function Logo() {
    return (
        <Link to="/">
            <div className={s.logo} />
        </Link>
    );
}
