import React from "react";
import classnames from "classnames";

import MegaMenuCol from "../MegaMenuCol";
import { IMegaMenuColProps } from "../MegaMenuCol/types";
import { IMegaMenuProps } from "./types";

import * as s from "./styles.module.scss";

export default function MegaMenu(props: IMegaMenuProps) {
    const { children, className = "", currentCat } = props;

    return (
        <div className={classnames(className, s.megamenu)}>
            <div className={s.megamenuRow}>
                {children.map((column: IMegaMenuColProps, index: number) => {
                    return (
                        <MegaMenuCol
                            key={index}
                            links={column}
                            currentCat={currentCat}
                        />
                    );
                })}
            </div>
        </div>
    );
}
