const isIE =
    typeof window !== "undefined" &&
    (window.navigator.userAgent.indexOf("MSIE") !== -1 ||
        window.navigator.appVersion.indexOf("Trident/") > -1);

const isEdge =
    typeof window !== "undefined" && /Edge\/\d./i.test(navigator.userAgent);

const isIos =
    typeof window !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream;

const isSafari =
    typeof window !== "undefined" &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const supportsNativeSmoothScroll =
    typeof document !== "undefined" &&
    "scrollBehavior" in document.documentElement.style;

// polyfilled smooth scrolling for IE, Edge & Safari
const smoothScrollTo = (to, duration) => {
    const element = document.scrollingElement || document.documentElement,
        start = element.scrollTop,
        change = to - start,
        startDate = +new Date();

    // t = current time
    // b = start value
    // c = change in value
    // d = duration
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(
            easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
        } else {
            element.scrollTop = to;
        }
    };
    animateScroll();
};

export {
    isIE,
    isEdge,
    isIos,
    isSafari,
    supportsNativeSmoothScroll,
    smoothScrollTo,
};
