import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import domains from "../../../../../configuration/domains";
import { IMegaMenuItemProps } from "./types";

import * as s from "./styles.module.scss";

export default function MegaMenuItem(props: IMegaMenuItemProps) {
    const { image, title, href, isExternal, currentCat } = props;

    function renderMegamenuItem() {
        return (
            image && (
                <div
                    className={classnames(s.megamenuItem, {
                        [s.active]: currentCat && title === currentCat,
                    })}
                    style={{
                        backgroundImage: `url('${`${domains.imageCdnUrlOpt}/${image}`}')`,
                    }}
                >
                    <span>
                        {title}
                    </span>
                </div>
            )
        );
    }

    return isExternal ? (
        <a href={`${domains.baseUrl}${href}`}>{renderMegamenuItem()}></a>
    ) : (
        <Link to={href}>{renderMegamenuItem()}</Link>
    );
}
