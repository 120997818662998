import React, { useEffect } from "react";
import classNames from "classnames";

import Heading from "../../atoms/Heading";

import * as s from "./styles.module.scss";

type Props = {
    images: string[];
    heroBgPosition?: string;
    title?: string;
    subTitle?: string;
    titleSize?: string;
    children?: React.ReactChildren;
    className?: string;
    isFullBanner?: boolean;
};

export default function Hero(props: Props) {
    const {
        images,
        title,
        subTitle,
        titleSize = "xxlarge",
        children,
        className,
        heroBgPosition = "center",
        isFullBanner = true,
    } = props;

    useEffect(() => {
        if (!isFullBanner) return;
        typeof window !== "undefined" &&
            window.addEventListener(
                "resize",
                () => {
                    typeof window !== "undefined" &&
                        window.pageYOffset !== 0 &&
                        addsHeightFn;
                },
                true
            );

        addsHeightFn();
    }, []);

    function addsHeightFn() {
        document.getElementById(
            "hero"
        ).style.height = `${window.innerHeight}px`;
    }

    return (
        <div
            id="hero"
            className={classNames(s.hero, className, {
                [s.fullBanner]: isFullBanner,
            })}
        >
            <div
                className={classNames(s.background, s.mobile)}
                style={{
                    backgroundImage: `url('${images[0]}')`,
                    backgroundPosition: heroBgPosition,
                }}
            />
            <div
                className={classNames(s.background, s.tablet)}
                style={{ backgroundImage: `url('${images[1]}')` }}
            />
            <div
                className={classNames(s.background, s.desktop)}
                style={{ backgroundImage: `url('${images[2]}')` }}
            />
            <div className={s.container}>
                {title ? (
                    <>
                        <Heading
                            size={titleSize}
                            level={1}
                            className={s.heading}
                        >
                            {title}
                        </Heading>
                        {subTitle ? (
                            <p className={s.subTitle}>{subTitle}</p>
                        ) : null}
                    </>
                ) : null}

                {children}
            </div>
        </div>
    );
}
