import React, { useState } from "react";
import classnames from "classnames";

import Arrow from "../../../../atoms/Arrow";
import SecondLevelMenuItem from "../SecondLevelMenuItem";

import { IMenuItemProps } from "./types";

import * as s from "./styles.module.scss";

export default function MenuItem(props: IMenuItemProps) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [activeSecondLevelIndex, setActiveSecondLevelIndex] = useState(null);

    function toggleSecondLevelDropdown(index: number) {
        setActiveSecondLevelIndex(
            activeSecondLevelIndex === index ? null : index
        );
    }

    const { link, toggle } = props;
    const { title, href, children, isExternal } = link;

    return (
        <li className={s.menuItem}>
            {!children &&
                (!isExternal ? (
                    <a
                        className={classnames(s.menuItemLink, s.noChildren)}
                        onClick={toggle}
                        href={href}
                    >
                        {title}
                    </a>
                ) : (
                    <a
                        className={s.menuItemLink}
                        onClick={toggle}
                        href={href}
                        target="_blank"
                    >
                        {title}
                    </a>
                ))}

            {children && (
                <div
                    className={s.menuItemLink}
                    onClick={() => setShowDropdown(!showDropdown)}
                >
                    {title}
                    <Arrow
                        className={s.arrow}
                        direction={showDropdown ? "up" : "down"}
                    />
                </div>
            )}

            {children && (
                <ul
                    className={classnames(s.dropdown, {
                        [s.hidden]: !showDropdown,
                    })}
                >
                    {children.map((child, index) => (
                        <li key={`menu-item-${index}`}>
                            {child.links ? (
                                <div
                                    className={s.secondLevelMenuItem}
                                    onClick={() =>
                                        toggleSecondLevelDropdown(index)
                                    }
                                >
                                    <SecondLevelMenuItem
                                        title={child.title}
                                        links={child.links}
                                        index={index}
                                        activeSecondLevelIndex={
                                            activeSecondLevelIndex
                                        }
                                        toggle={() =>
                                            toggleSecondLevelDropdown(index)
                                        }
                                    />
                                </div>
                            ) : child.openNewTab ? (
                                <a href={child.href} target="no_blank">
                                    {child.title}
                                </a>
                            ) : (
                                <a href={child.href}>{child.title}</a>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
}
