import React from "react";
import MegaMenuItem from "../MegaMenuItem";

import { IMegaMenuColProps } from "./types";
import { IMegaMenuItemProps } from "../MegaMenuItem/types";

import * as s from "./styles.module.scss";

export default function MegaMenuCol(props: IMegaMenuColProps) {
    const { links, currentCat } = props;

    if (!links) return null;

    function renderChildren() {
        return (
            links.children &&
            links.children.map((item: IMegaMenuItemProps, index: number) => (
                <li key={index}>
                    <MegaMenuItem currentCat={currentCat} {...item} />
                </li>
            ))
        );
    }

    return (
        <div className={s.megamenuCol}>
            <ul className={s.megamenuItems}>
                <li>
                    <MegaMenuItem currentCat={currentCat} {...links} />
                </li>
            </ul>
        </div>
    );
}
