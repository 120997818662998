import React, { useState, useRef } from "react";

import BurgerButton from "./BurgerButton";
import classnames from "classnames";
import HeaderMenu from "./Desktop/HeaderMenu";
import Logo from "../../atoms/Logo";
import Navigation from "./Mobile/Navigation";

import { INavProps } from "./types";

import * as s from "./styles.module.scss";
import { default as headerConfig } from "./headerConfig.json";

export default function Nav(props: INavProps) {
    const { currentCat } = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);

    const navRef = useRef();

    function toggleMobileMenu() {
        setIsSecondaryMenuOpen(false);
        setIsMenuOpen(!isMenuOpen);
    }

    function toggleSecondaryMobileMenu() {
        setIsSecondaryMenuOpen(!isSecondaryMenuOpen);
    }

    return (
        <>
            <header
                ref={navRef}
                className={classnames("container-fluid", s.mobileHeader)}
            >
                <div className={s.headerContainer}>
                    <div className={s.logo}>
                        <Logo />
                    </div>
                    <Navigation
                        mainLinks={headerConfig.main.menu as any}
                        mobileMenuOpen={isMenuOpen}
                        toggle={toggleMobileMenu}
                    />
                    <HeaderMenu
                        isMobileMenuOpen={isMenuOpen}
                        currentCat={currentCat}
                    />
                    <div className={`${s.burger}`}>
                        <BurgerButton
                            mobileMenuOpen={isMenuOpen}
                            toggle={toggleMobileMenu}
                        />
                    </div>
                </div>
            </header>
            {isMenuOpen && <div className={s.modalOverlay} />}
        </>
    );
}
