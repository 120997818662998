import React from "react";
import MenuItem from "../MenuItem";

import { INavigationProps } from "./types";

import * as s from "./styles.module.scss";

export default function Navigation(props: INavigationProps) {
    const { mainLinks, mobileMenuOpen, toggle } = props;

    return (
        <div className={mobileMenuOpen ? s.mobileMenuVisible : s.mobileMenu}>
            <ul className={s.nav}>
                {mainLinks &&
                    mainLinks.length &&
                    mainLinks.map((link, index) => (
                        <MenuItem
                            key={`mobile-menu-${index}`}
                            link={link}
                            toggle={toggle}
                        />
                    ))}
            </ul>
        </div>
    );
}
